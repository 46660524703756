/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import "sass-lib";

@media(min-width: 991px){
	header{
	 	.nav_header{
	 		.nav_left{display: block !important;}
	 		.nav_mobile{display: none !important;}
	 	}
		
	}
}
@media(min-width: 1px) and (max-width: 990px){
	h1{font-size: 25px;}
	h4{font-size: 20px;}

	.wrapper-lg, .wrapper{width: 100%; padding: 0 40px; @include boxSizing(border-box);}
	.testiHome {
		.wrapper {width: auto;}
	}
	.wrapper{padding: 0 70px;}
	.box-title{
		padding-left: 0;
		/*.line-vly{left: -30px; width: 6px; height: auto; top: 0;}*/
		.line-vly{left: -30px; width: 6px; height: 72px;}
		&.nopadd{
			padding-left: 0;
			.line-vly{left: -30px;}
		}
	}

	header{
		position: fixed;
		.nav_header{
			width: auto; margin-top: 21px;
			.nav_left{display: none;}
			.nav_right{float: left;margin-right: 22px;}
			.nav_mobile{
				position: fixed; top: 0; left: auto; right: -400px; width: 335px; background: $green; z-index: 10; padding-top: 80px; display: none; max-height: 100vh; overflow-y: auto; @include boxSizing(border-box);
				ul{
					display: block; position: relative;
					&:after{content: " ";clear: both; display: block;}
				}
				li{
					display: block; width: 100%; background: $blue; margin-left: 0; @include boxSizing(border-box);
					text-align: left; padding: 30px 30px 30px 60px; position: relative;
					&:after{content: ""; position: absolute; left: 30px; right: 30px; bottom: 0; height: 1px; background: #355f8d;}
					a{color: $white;}
					.icwp{position: absolute; top: 25px; left: 28px;}
					&.active{
						background: $green; border-bottom: 2px solid $white;
						a{color: $white;}
					}
					&:last-child{border-bottom: none;}
				}
				.bg-close{position: fixed; top: 0px; right: 0px; width: 335px; height: 80px; background: $green; z-index: 1; @include transition(0.3s all);}
				.box-sosmed-mb{
					text-align: right; padding-top: 145px; padding-bottom: 40px; padding-right: 30px; position: relative; display: block; background: $blue; @include boxSizing(border-box);
					&:after{content: " ";clear: both; display: block;}
					a{margin-left: 10px;}
				}
				&.active{
					.bg-close{background: $green; @include transitionDelay(0.5s);}
				}
			}
		}
		.burg-menu{
			display: block; position: relative; width: 30px; height: 23px; float: right; z-index: 11;
			span{
				position: absolute; top: 0; left: 0; width: 100%; height: 3px; background: $green; @include transition(0.3s all);
				&:nth-child(2){top: 10px;}
				&:nth-child(3){top: 20px;}
			}
			&.active{
				span{
					background: $white; top: 50%; margin-top: -1.5px;
					&:first-child{@include transform(rotate(45deg)); @include transformOrigin(50% 50%); left: 0;}
					&:nth-child(2){opacity: 0;}
					&:last-child{@include transform(rotate(-45deg)); @include transformOrigin(50% 50%); right: 0; left: auto;}
				}
			}
		}

		&.float{
			.nav_header{
				margin-top: 21px;
			}
		}
	}

	.slidehome{
		.slick-prev, .slick-next{display: none !important;}
		.list-slide{
			.desc{
				h1{font-size: 40px; line-height: 50px;}
			}
		}
	}
	.box-proteksi{
		.bg-proteksi{height: 400px;}
		.img-proteksi{width: 50%;}
		.desc-proteksi{
			width: 45%; height: 400px;
			h3{font-size: 25px; line-height: 28px;}
			p{font-size: 14px; line-height: 22px;}
		}
		.center{display: none;}
		.left, .right{width: 100%;}
		.left{
			.desc-proteksi{padding-right: 70px;}
		}
		.right{
			.desc-proteksi{padding-left: 70px;}
		}
	}
	.sec-about{
		.left{
			figure{
				height: 400px; position: relative;
				img{position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%)); min-width: 100%; max-width: none; height: 100%;}
			}
		}
		.right{
			width: 36%;
			p{font-size: 12px; line-height: 22px;}
		}
	}
	.box-banner-solution{
		position: relative;
		h2{
			&.hline{
				.line-yl{display: block;}
			}
		}
		.left{width: 43.54%; @include boxSizing(border-box); padding-left: 50px;}
		.right{width: 54%; position: absolute; top: 50%; right: 40px; @include transform(translateY(-50%));}
	}
	.box-news-home{
		.box-title-news{width: 28%;}
		.box-news-list-home{
			width: 70%;
		}
	}
	.box-news-list-home{
		.list{
			.date{
				width: 50px; padding: 5px 10px;
			}
			.desc{
				padding-left: 65px;
				h4{font-size: 16px;}
				p{font-size: 12px;}
			}
		}
	}

	.box-btn-tab{
		position: relative;
		.title-btn-tab{
			display: block; font-size: 16px; line-height: 50px; font-weight: 700; color: $blue; text-transform: uppercase; height: 50px; @include borderRadius(0); border: 1px solid #c2c2c2;
			background: url('../images/material/arr-select-box-black.png') no-repeat right center; padding: 0 40px; text-align: center;
			&.active{@include borderRadius(0);}
		}
		.inner-btn-tab{
			width: 100%; position: absolute; top: 100%; left: 0; right: 0; z-index: 10; background: $white;@include borderRadius(0); border: 1px solid #c2c2c2; border-top: none; padding: 0 20px 10px; display: none;
			.bgdragg{display: none;}
			/*min-width: 90%; max-width: 100%;*/
			/*width: 100%;*/
			/*.bgdragg{width: 33.333333333333333%;}*/
		}
		&.blleft{
			text-align: center;
		}
		.list{
			width: 100%; @include borderRadius(0); border: none; border-bottom: 1px solid #c2c2c2; text-align: center;
			&:nth-child(2){@include borderRadius(0); border: none;border-bottom: 1px solid #c2c2c2;}
			&:last-child{@include borderRadius(0);border: none;}
			&.active{
				color: $blue;
			}
		}
		&.board-btn{
			.list{width: 100%;}
		}
		/*&.board-two-list{
			.inner-btn-tab{
				.bgdragg{width: 50%;}
			}
			.list{width: 50%;}
		}*/
		/*&.board-btn-layanan{
			.list{width: 33.333333333333333%; padding: 0 5px; font-size: 14px;}
			.inner-btn-tab{
				width: 100%;
				.bgdragg{width: 33.333333333333333%;}
			}
		}*/
	}

	.box-product{
		margin-top: 35px;
		.list-product{
			margin-bottom: 60px;
			p{max-height: none;}
			.img-prod{width: 100%; margin-bottom: 30px;}
			.desc-prod{width: 100%; padding-left: 60px; @include boxSizing(border-box);}
		}
	}
	.box-two-list{
		.list{
			width: 100%; margin-bottom: 35px;
			figure{
				height: 300px; position: relative; overflow: hidden;
				img{position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%)); min-width: 100%; max-width: none; min-height: 100%;}
			}
			.left{width: 48%;}
			.right{width: 41%;}
			&:nth-child(2n){
				.left{float: right; text-align: right;}
				.right{
					right: auto;left: 0; text-align: right;
					.hline{
						.line-yl{left: auto; right: -40px;}
					}
				}
			}
		}
	}

	aside{
		width: auto; text-align: center; padding-top: 0; position: relative; float: none; margin: 0 -70px; display: block;
		ul{
			background: $blue; position: absolute; top: 90%; left: 0; right: 0; z-index: 10; display: none;
			li{
				margin-bottom: 0; padding: 20px 0; border-bottom: 1px solid #245283;
				a{color: $white; font-size: 16px;}
			}
		}
		.title-aside{
			position: relative; top: -4px; padding: 15px 0; border-bottom: 1px solid #245283; background: $green; color: $white; font-size: 16px; font-weight: 700;
			span{margin-right: 10px;}
		}
	}
	article{
		width: 100%; padding: 40px 0 30px;
		.box-title{
			.line-vly{left: -30px; top: 0; height: auto;}
		}
	}
	/*.banner{width: 100%; float: left;}*/
	.box-drag-nav-slide{
		&.ui-slider-horizontal{
			.ui-slider-handle{width: 100px;}
		}
	}
	.thankspage{
		.desc{
			h4{font-size: 18px; padding: 0 40px;}
		}
	}

	.box-news-list{
		.list{width: 50%; margin-bottom: 40px;}
	}
	.box-news-vids{
		p{max-height: 72px;}
		.left{width: 49%;}
		.right{width: 41%; padding-top: 20px;}
	}

	.box-detail-news{
		.box-title{
			padding-left: 0;
			.line-vly{height: auto; top: 0; left: -30px;}
		}
		.left-news{width: 100%; padding-bottom: 30px; margin-bottom: 30px; border-bottom: 1px solid #e6e6e6;}
		.right-news{
			width: 100%;
			.box-filter{display: none;}
		}
	}
	.box-list-news-right{
		&:after{content: " "; clear: both; display: block;}
		.list-news{
			width: 50%; float: left; padding-right: 15px; @include boxSizing(border-box);
			&:nth-child(2n){padding-left: 15px; padding-right: 0;}
			&:nth-child(n + 3){display: none;}
			.left{width: 24%;}
			.right{width: 70%;}
		}
	}
	.box-sitemap{padding-left: 0;}

	.box-manfaat{
		.list-manfaat{
			width: 50%;
			&:nth-child(3n + 1){clear: inherit;}
			&:nth-child(2n + 1){clear: both;}
		}
	}

	.ls-Testi {
		.slide {width: 350px; padding-bottom: 25px;}
		.box_testi {
			h3 {font-size: 18px; margin-bottom: 10px;}
			h4 {font-size: 16px;}
			p {font-size: 14px; height: 150px;}
			&:before {left: 15px; right: 15px; top: 15px; bottom: 15px;}
		}
		.slick-dots {@include transform(scale(0.85));}
	}

	footer{
		.foot-top{
			padding-bottom: 80px;
			.prof-foot{width: 40%;}
			.box-link-foot{
				width: 50%; padding-top: 50px;
				h5{
					margin-bottom: 0;
					&:before{top: 7px;}
				}
				.box-link-foot-left{
					width: 100%;
					.list-link-foot{
						width: 100%; margin-bottom: 0;
						&:nth-child(2n + 1){clear: inherit;}
						> a{width: 100%; display: inline-block; margin-bottom: 25px;}
						ul{display: none;}
					}
				}
				.box-link-sosmed{
					width: 100%; padding-left: 52px; @include boxSizing(border-box);
					ul{display: none;}
				}
			}
		}
		.foot-bottom{font-size: 12px;}
	}
	.section-telemarketing {
		padding: 50px 0;
		.title-section {
			font-size: 20px;line-height: 30px;
			br {display: none;}
		}
		.list-box {
			.box-item {align-items: flex-start;}
			figure {width: 300px;}
			figcaption {
				width: calc(100% - 330px); padding-left: 40px;
				&:before {height: 4px; width: 24px;}
			}
		}
		.form-cek {
			display: block;
			.cek-number {
				width: 100%; margin-bottom: 20px;
				form {
					position: relative; border-radius: 25px;width: 380px;
					input[type="text"],
					input[type="number"] {
						height: 50px;
					}
					button {
						font-size: 14px; border-radius: 25px;
					}
				}
			}
			> p {width: 100%}
		}
	}
}

@media(min-width: 1px) and (max-width: 830px){
	footer{
		.foot-bottom{
			text-align: center; padding: 15px 0 35px; position: relative;
			.left{width: 100%; padding-top: 0; position: absolute; bottom: 15px; left: 0; right: 0;}
			.right{
				width: 100%; position: relative; padding-top: 65px;
				span{width: 100%; text-align: center; padding-top: 0;}
				figure{float: none; position: absolute; top: 0; left: 0; right: 0;}
			}
		}
	}
}

@media(min-width: 1px) and (max-width: 790px){
	.box-filter{
		margin-bottom: 30px;
		&.box-filter-rekanan{
			select{width: 100%; margin-bottom: 20px;}
			.btn-submit{width: 100%;}
		}
	}
	.box-content{
		.wrap_btn2 {
			a{
				display: inline-block; float: none; margin-bottom: 10px;
			}
		}
		p {
		    margin: 25px 0 15px;
		    font-size: 16px; line-height: 24px;
		}
	}
}

@media (max-width: 1023px){
	.wrap_popup {
		.pop_inner {
			.content_pop {width: 650px;}
		}
		article {
			.box-title {
				margin: 0;
				width: auto;
				padding-left: 30px;
				.line-vly {left: 0;}
			}
		}
	}
	.wrap-notif .pop_inner .content_pop {
		width: 420px; padding: 40px 30px 50px;
		.notif-telemarketing {
			.ico {width: 80px; margin: 0 auto 25px;}
			p {font-size: 16px}
			.close-notif {line-height: 40px; min-width: 140px;}
		}

	}
}


